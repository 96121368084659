@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body,
html {
  height: 100%;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.modal {
  background-color: white;
  width: 70vw;
  height: 65vh;
  border-radius: 10px;
}

.close {
  text-align: right;
  cursor: pointer;
  font-size: 2em;
  margin-right: 10px;
  margin-bottom: 0px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.textContainer > h1 {
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: center;
}

.textContainer > div {
  height: 35vh;
  width: 50vw;
}

.textContainer > div > p {
  font-weight: bold;
  color: red;
}

.sidebar > p,
.sidebar > h2,
.sidebar > * {
  color: white;
  scrollbar-color: #fff #202632;
}

label {
  color: white;
}

.container-fluid {
  display: flex;
  height: 100%;
}

.sidebar {
  height: 100vh;
  overflow-y: scroll;
  width: 60vw;
  min-width: 200px;
  background: #202632;
  padding: 10px;
  padding-bottom: 0;
}

#license-types {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

#license-types label {
  display: inline-block;
  margin-right: 4px;
}

#map {
  flex: 1;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 6px;
  background: conic-gradient(from 135deg at top, currentColor 90deg, #0000 0) 0 calc(50% - 4px) / 17px 8.5px,
    radial-gradient(farthest-side at bottom left, #0000 calc(100% - 6px), currentColor calc(100% - 5px) 99%, #0000) top right/50% 50% content-box content-box,
    radial-gradient(farthest-side at top, #0000 calc(100% - 6px), currentColor calc(100% - 5px) 99%, #0000) bottom / 100% 50% content-box content-box;
  background-repeat: no-repeat;
  animation: l11 1s infinite linear;
}
@keyframes l11 {
  100% {
    transform: rotate(1turn);
  }
}

.school-name-interactive {
  cursor: pointer;
}
.school-name-interactive:hover {
  cursor: pointer;
  text-decoration: underline;
}

.leaflet-popup-content {
  margin: 0;
}

.leaflet-popup-content-wrapper {
padding: 0px;
}